window.addEventListener('load', (event) => {
  (function () {
    console.ow = function () {
      const owLoggerId = 'ow-logger';
      const logger = document.getElementById(owLoggerId);

      const date = new Date();
      const dateFormat = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`

      if (!logger) return;
      let output = '', arg, i;

      output += '<div>'
      output += `<span class="ow-logger-date">${dateFormat}:&nbsp;</span>`;

      for (i = 0; i < arguments.length; i++) {
        arg = arguments[i];
        output += `<span class="log-${typeof arg}">`;

        if (
          typeof arg === "object" &&
          typeof JSON === "object" &&
          typeof JSON.stringify === "function"
        ) {
          output += JSON.stringify(arg);
        } else {
          output += arg;
        }

        output += '</span>&nbsp;';
      }
      output += '</div>'

      logger.innerHTML = `${output + logger.innerHTML}`;
    };
  })();
});
