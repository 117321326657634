class OwError {
  static addSystem(system) {
    OwError.AVAILABLE_SYSTEMS[system.name] = system;
  }

  get usingSystemName() {
    return this.config.usingSystemName;
  }

  get usingSystem() {
    return OwError.AVAILABLE_SYSTEMS[this.usingSystemName];
  }

  constructor({config}) {
    this.config = config;
    this.initSystem();
    this.clearErrors();
  }

  initSystem() {
    const system = this.config[this.usingSystemName];
    this.usingSystem.init(system.config);
  }

  clearErrors() {
    this.errors = [];
  }

  pushError(error) {
    const errorExistInList = this.errors.some(_error => _error.toString() == error.toString());
    if (errorExistInList) {
      return;
    }

    this.errors.push(error);
    this.sendError(error);
  }

  sendError({error}) {
    this.usingSystem.sendError({error});
  }

  sendMessage({message, level}) {
    this.usingSystem.sendMessage({message, level});
  }
}

OwError.SYSTEM = {
  SENTRY: 'sentry',
}
OwError.AVAILABLE_SYSTEMS = {};
