class OwErrorSystemSentry {
  constructor() {
    this.name = OwError.SYSTEM.SENTRY;
    OwError.addSystem(this);
  }

  init(config) {
    Sentry.init(config);
  }

  sendError({error}) {
    this.setUser();
    Sentry.captureException(error)
  }

  sendMessage({message, level}) {
    this.setUser();
    Sentry.captureMessage(message, {
      level,
    })
  }

  setUser() {
    this.clearUser();
    const user = window['userStore'];

    if (user) {
      Sentry.setUser({
        id: user.id
      });
    }
  }

  clearUser() {
    Sentry.configureScope(scope => scope.setUser(null));
  }

  getTags() {
    const tags = {};
    const user = window['userStore'];

    if (user) {
      tags.userId = user.id
    }

    return tags;
  }
}

new OwErrorSystemSentry();
